import { HStack, Image, Link, Text } from "@chakra-ui/react";
import LOGO from "../assets/logo.svg";
import OMNITY from "../assets/omnity.svg";
import { ArrowUpRight } from "lucide-react";
import { useLocation } from "react-router-dom";

const LINKS = [
  {
    name: "Runes",
    url: "/runes",
  },
  // {
  //   name: "BRC-20",
  //   url: "/brc20",
  // },
  {
    name: "BTC",
    url: "/ckbtc",
  },
  {
    name: "Solana",
    url: "/solana",
  },
  // {
  //   name: "DOGE",
  //   url: "/doge",
  // },
  {
    name: "ICP",
    url: "/icp",
  },
];

export default function Header() {
  const location = useLocation();
  return (
    <HStack
      zIndex={100}
      px={{ base: 4, md: 16 }}
      py={{ base: 2, md: 8 }}
      justifyContent="space-between"
    >
      <Link href="/">
        <HStack alignItems="center" gap={2}>
          <Image src={LOGO} alt="logo" />
          <Image
            src={OMNITY}
            pos="relative"
            top={1}
            alt="omnity"
            display={{ base: "none", md: "inline-block" }}
          />
        </HStack>
      </Link>

      <HStack gap={{ base: 2, md: 6 }}>
        {LINKS.map((link) => {
          const isActive = location.pathname.startsWith(link.url);
          return (
            <Link key={link.url} href={link.url}>
              <HStack
                color={isActive ? "gray.100" : "gray.500"}
                _hover={{ color: "gray.100" }}
              >
                <Text fontSize={20} fontWeight={600}>
                  {link.name}
                </Text>
              </HStack>
            </Link>
          );
        })}
      </HStack>

      <HStack gap={8} display={{ base: "none", md: "flex" }}>
        <Link
          href="https://explorer.omnity.network"
          target="_blank"
          role="group"
          _hover={{ color: "blue.400", textDecoration: "underline" }}
        >
          <HStack>
            <Text fontSize={20}>Explorer</Text>
            <ArrowUpRight size={20} />
          </HStack>
        </Link>
      </HStack>
    </HStack>
  );
}
